/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  getAudioDevice,
  getVideoDevice
} from "store/modules/сandidates/selectors";
import Mic from "assets/images/candidate/mic.png";
import Cam from "assets/images/candidate/video.png";
import { store } from "store";
import {
  saveDeviceInfo,
  setAudioDevice,
  setVideoDevice
} from "store/modules/сandidates/actions";
import { useDetectClickOutside } from "react-detect-click-outside";

const AudioVideoDevice = ({
  isRecording,
  isShowDelayMessage,
  isShowControls,
  videoDevices,
  audioDevices,
  selectedAudioDevice,
  selectedVideoDevice,
  onlyAudio,
  isMediaUploadInterrupted,
  userId
}) => {
  const [devicesListOpen, setDevicesListOpen] = useState(null);
  const audioDevicesRef = useDetectClickOutside({
    onTriggered: () => {
      if (devicesListOpen === "audio") {
        setDevicesListOpen(null);
      }
    }
  });
  const videoDevicesRef = useDetectClickOutside({
    onTriggered: () => {
      if (devicesListOpen === "video") {
        setDevicesListOpen(null);
      }
    }
  });
  const audioDeviceLabel = audioDevices?.find(
    device => device.deviceId === selectedAudioDevice
  )?.name;

  const sortedAudioDevices = [...(audioDevices ?? [])]?.sort((a, b) =>
    (a.deviceId === selectedAudioDevice
      ? -1
      : b.deviceId === selectedAudioDevice
      ? 1
      : 0));

  const sortedVideoDevices = [...(videoDevices ?? [])]?.sort((a, b) =>
    (a.deviceId === selectedVideoDevice
      ? -1
      : b.deviceId === selectedVideoDevice
      ? 1
      : 0));

  const videoDeviceLabel = videoDevices?.find(
    device => device.deviceId === selectedVideoDevice
  )?.name;

  return (
    <div
      style={{
        marginTop: 20
      }}
    >
      <div className="devices__container">
        {!isRecording &&
          !isShowDelayMessage &&
          !isShowControls &&
          !onlyAudio &&
          videoDevices.length > 1 &&
          !isMediaUploadInterrupted && (
            <div className="video-devices__container">
              <div
                className={`video__switch ${
                  videoDevices.length > 1 ? "devices" : ""
                }`}
                onClick={
                  () => {
                    setDevicesListOpen(prev => (!prev ? "video" : null));
                  }
                }
                onKeyDown={() => setDevicesListOpen(null)}
                role="button"
                tabIndex={0}
              >
                <img src={Cam} alt="cam" style={{ height: 15, width: 15 }} />
                <div>{videoDeviceLabel || "Select Camera"}</div>
              </div>
              {devicesListOpen === "video" &&
                !isRecording &&
                !isShowDelayMessage &&
                !isShowControls && (
                  <div className="video__camera-devices" ref={videoDevicesRef}>
                    {sortedVideoDevices?.map(videoDevice => (
                      <span
                        className={
                          selectedVideoDevice === videoDevice.deviceId
                            ? "video__camera-device active"
                            : "video__camera-device"
                        }
                        onClick={() => {
                          store.dispatch(setVideoDevice(videoDevice?.deviceId));
                          store.dispatch(saveDeviceInfo({
                            userId,
                            deviceInfo: { camera_device: videoDevice?.deviceId }
                          }));
                          setDevicesListOpen(null);
                        }}
                        onKeyDown={() => {
                          store.dispatch(setVideoDevice(videoDevice?.deviceId));
                          store.dispatch(saveDeviceInfo({
                            userId,
                            deviceInfo: { camera_device: videoDevice?.deviceId }
                          }));
                          setDevicesListOpen(null);
                        }}
                        role="button"
                        tabIndex={0}
                        key={videoDevice?.deviceId}
                      >
                        {videoDevice?.name}
                      </span>
                    ))}
                  </div>
                )}
            </div>
          )}
        {!isRecording &&
          !isShowDelayMessage &&
          !isShowControls &&
          audioDevices?.length > 1 &&
          !isMediaUploadInterrupted && (
            <div className="audio-devices__container" ref={audioDevicesRef}>
              {devicesListOpen === "audio" &&
                !isRecording &&
                !isShowDelayMessage &&
                !isShowControls && (
                  <div className="video__audio-devices">
                    {sortedAudioDevices?.map(audioDevice => (
                      <span
                        className={
                          selectedAudioDevice === audioDevice?.deviceId
                            ? "video__camera-device active"
                            : "video__camera-device"
                        }
                        onClick={() => {
                          store.dispatch(setAudioDevice(audioDevice?.deviceId));
                          store.dispatch(saveDeviceInfo({
                            userId,
                            deviceInfo: { microphone_device: audioDevice?.deviceId }
                          }));
                          setDevicesListOpen(null);
                        }}
                        onKeyDown={() => {
                          store.dispatch(setAudioDevice(audioDevice?.deviceId));
                          store.dispatch(saveDeviceInfo({
                            userId,
                            deviceInfo: { microphone_device: audioDevice?.deviceId }
                          }));
                          setDevicesListOpen(null);
                        }}
                        role="button"
                        tabIndex={0}
                        key={audioDevice?.deviceId}
                      >
                        {audioDevice?.name}
                      </span>
                    ))}
                  </div>
                )}
              <div
                className={`audio__switch ${
                  audioDevices?.length > 1 ? "devices" : ""
                }`}
                onClick={
                  audioDevices?.length > 1
                    ? () => setDevicesListOpen(prev => (!prev ? "audio" : null))
                    : null
                }
                onKeyDown={() => setDevicesListOpen(null)}
                role="button"
                tabIndex={0}
              >
                <img src={Mic} alt="mic" style={{ height: 15 }} />
                <div>{audioDeviceLabel || "Select Microphone"}</div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

AudioVideoDevice.defaultProps = {
  isMediaUploadInterrupted: false
};

AudioVideoDevice.propTypes = {
  audioDevices: PropTypes.arrayOf(
    PropTypes.shape({
      deviceId: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  isRecording: PropTypes.bool.isRequired,
  isShowControls: PropTypes.bool.isRequired,
  onlyAudio: PropTypes.bool.isRequired,
  isShowDelayMessage: PropTypes.bool.isRequired,
  selectedVideoDevice: PropTypes.string.isRequired,
  selectedAudioDevice: PropTypes.string.isRequired,
  videoDevices: PropTypes.arrayOf(
    PropTypes.shape({
      deviceId: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  isMediaUploadInterrupted: PropTypes.bool,
  userId: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  selectedAudioDevice: getAudioDevice(state),
  selectedVideoDevice: getVideoDevice(state)
});

export default connect(mapStateToProps)(AudioVideoDevice);
